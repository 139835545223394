import { useField } from "formik";
import React, { forwardRef, memo } from "react";

const FileField = forwardRef(({ type, name, id, ...rest }, ref) => {
    const [, , setfield] = useField(name);
    return (
        <input
            {...rest}
            ref={ref}
            name={name}
            id={id}
            type="file"
            onChange={e => setfield.setValue(e.target.files[0])}
        />
    );
});

export default memo(FileField);
