import { getCookie } from "../utils/functions";

export const authHeader = () => {
    const token = getCookie("diamond-card-t");
    if (token) {
        return {
            Authorization: `Bearer ${token}`,
        };
    }
    return {};
};
