import { FaRegUser, FaRegImages, FaFacebookF } from "react-icons/fa";
import {
    FiLogOut,
    FiChevronLeft,
    FiMenu,
    FiChevronRight,
} from "react-icons/fi";
import {
    MdOutlineLanguage,
    MdEmail,
    MdOutlineNotificationsNone,
} from "react-icons/md";
import { RiTelegramLine } from "react-icons/ri";
import {
    HiOutlineUsers,
    HiOutlineArrowNarrowUp,
    HiOutlineUserAdd,
    HiOutlineArrowNarrowDown,
} from "react-icons/hi";
import {
    AiOutlineAppstore,
    AiOutlineAppstoreAdd,
    AiOutlineFileProtect,
    AiOutlineFileText,
    AiOutlineFileSearch,
    AiOutlineDollar,
    AiFillEyeInvisible,
    AiFillEye,
    AiOutlineSearch,
    AiOutlineHome,
    AiOutlineInfoCircle,
} from "react-icons/ai";
import { BiSortAlt2 } from "react-icons/bi";
import { IoLogoWhatsapp } from "react-icons/io5";
export const icon = {
    user: <FaRegUser />,
    logout: <FiLogOut />,
    chevronR: <FiChevronRight />,
    chevronL: <FiChevronLeft />,
    menu: <FiMenu />,
    lang: <MdOutlineLanguage />,
    home: <AiOutlineHome />,
    products: <AiOutlineAppstore />,
    addProduct: <AiOutlineAppstoreAdd />,
    orders: <AiOutlineFileText />,
    ordersAll: <AiOutlineFileProtect />,
    archiveOrders: <AiOutlineFileSearch />,
    users: <HiOutlineUsers />,
    telegram: <RiTelegramLine />,
    addUser: <HiOutlineUserAdd />,
    dollar: <AiOutlineDollar />,
    eyeShow: <AiFillEye />,
    search: <AiOutlineSearch />,
    eyeHide: <AiFillEyeInvisible />,
    sortUp: <HiOutlineArrowNarrowUp />,
    sortDown: <HiOutlineArrowNarrowDown />,
    unsorted: <BiSortAlt2 />,
    notify: <MdOutlineNotificationsNone />,
    ads: <FaRegImages />,
    facebook: <FaFacebookF />,
    whatsapp: <IoLogoWhatsapp />,
    info: <AiOutlineInfoCircle />,
    email: <MdEmail />,
};
