export const imageFormat = ["jpg", "gif", "png", "jpeg", "svg", "webp"];
export const setCookie = (cname, cvalue, expire) => {
  let d;
  if (expire !== -1) {
    d = new Date(expire);
  }
  let expires = expire === -1 ? "expires=-1" : "expires=" + d;

  document.cookie =
    cname +
    "=" +
    cvalue +
    ";" +
    expires +
    ";path=/";
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
export const formattedLevel = (level) => {
  switch (level) {
    case "level_one":
      return 1;
    case "level_two":
      return 2;
    case "level_three":
      return 3;
    default:
      return 1;
  }
};
export const orderStatusBtnStyle = (value) => {
  switch (value) {
    case "نجح":
    case "success":
      return "success";
    case "فشل":
    case "failed":
      return "danger";
    case "في الانتظار":
    case "inprogress":
      return "secondry";
    default:
      return "success";
  }
};
export const statusMessage = (msg) => {
  switch (msg) {
    case "success":
      return "نجح";
    case "failed":
      return "فشل";
    case "inprogress":
      return "في الانتظار";
    default:
      return "غير معرف";
  }
};
