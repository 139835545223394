import React, { memo } from "react";
import { useTranslation } from "react-i18next";
// import { icon } from "../utils/icons";

// const socialIcons = {
//     facebook: icon.facebook,
//     whatsapp: icon.whatsapp,
//     email: icon.email,
// };
const Footer = () => {
  const [t] = useTranslation(["home"]);
  const yearData = new Date();
  // const { data, isLoading, error } = useQuery("getAbout", () => getAbout(), {
  //     staleTime: Infinity,
  //     cacheTime: Infinity,
  // });

  // const socialMedia = data => {
  //     if (data.data?.social_media) {
  //         const socialList = JSON.parse(data.data.social_media);
  //         let elements = [];
  //         for (let key in socialList) {
  //             elements.push(
  //                 <li
  //                     key={key}
  //                     className="mx-1 text-sm lg:text-lg  bg-primary-color-500 rounded-md"
  //                 >
  //                     <Link
  //                         className="transition-all w-[30px]  p-2  h-[30px] lg:w-[40px] lg:h-[40px] hover:rotate-[360deg]  flex items-center justify-center  text-white"
  //                         to={
  //                             key === "email"
  //                                 ? `mailto:${socialList[key]}`
  //                                 : socialList[key]
  //                         }
  //                         target="_blank"
  //                     >
  //                         {socialIcons[key]}
  //                     </Link>
  //                 </li>
  //             );
  //         }
  //         return elements.map(e => e);
  //     }
  // };
  return (
    <div className=" fixed w-full bottom-0 border-t z-[10]">
      {/* <DCard className="bg-white">
                <div className="md:container md:mx-auto">
                    <div className="flex justify-center col-span-2 sm:col-span-1">
                        <div className="flex items-center">
                            <Heading
                                as="h2"
                                size={["sm", "sm", "md"]}
                                className="text-primary-color-500  text-center"
                            >
                                {t("contact-us-title", { ns: "footer" })}
                            </Heading>
                            <ul className="flex items-center text-2xl">
                                {isLoading
                                    ? [...Array(5).keys()].map(c => (
                                          <li key={c} className="my-4 mx-1">
                                              <Skeleton className="h-[40px] w-[40px]" />
                                          </li>
                                      ))
                                    : error
                                    ? null
                                    : socialMedia(data)}
                            </ul>
                        </div>
                    </div>
                </div>
            </DCard> */}
      <div className="bg-primary-color-500 text-white p-2 text-center text-sm lg:text-[1rem]">
        <p>
          {" "}
          {t("desc", { ns: "all-rights" }) + yearData.getFullYear()} &copy;{" "}
        </p>
      </div>
    </div>
  );
};

export default memo(Footer);
