import React, { useRef } from "react";

// import utils
import { routes } from "../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setAuth, setLanguage } from "../redux/slicers/config";
import { icon } from "../utils/icons";
import authServices from "../services/auth";
import { setCookie } from "../utils/functions";

const Sidebar = ({ navHeight, menuHandler, showSidebar, activePath }) => {
  const [t, i18n] = useTranslation();
  const { lang } = useSelector((state) => state.config);
  const selectRef = useRef();

  const languageHandel = () => {
    if (selectRef.current.value === "en") {
      dispatch(setLanguage("en"));
      i18n.changeLanguage("en");
      menuHandler();
    }
    if (selectRef.current.value === "ar") {
      dispatch(setLanguage("ar"));
      i18n.changeLanguage("ar");
      menuHandler();
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    authServices.logout();
    setCookie("diamond-card-t", "", -1);
    dispatch(setAuth(null));
    navigate("/login", { replace: true });
  };

  return (
    <div>
      <div
        style={{
          height: `calc(100vh - ${navHeight}px)`,
        }}
        className={`${
          showSidebar
            ? "translate-x-0"
            : i18n.language === "ar"
            ? "translate-x-full"
            : "-translate-x-full"
        }  sidebar start-0  transition-all flex flex-col justify-between lg:translate-x-0 w-[250px] md:hidden  bg-primary-color-500 absolute z-50 top-full p-4 text-white`}
      >
        <ul className=" text-[1rem] md:text-lg">
          {routes
            .filter((r) => r.type.includes("collapse"))
            .map((r) => {
              return (
                <li
                  onClick={menuHandler}
                  key={r.id}
                  className={`${
                    r.path === activePath
                      ? "bg-secondry-color-500 text-primary-color-500"
                      : ""
                  } transition-all hover:bg-secondry-color-500  rounded-lg hover:text-primary-color-500 mb-1`}
                >
                  <Link
                    to={r.name === "products" ? "/1/products" : r.path}
                    className="flex items-center p-1"
                  >
                    <p className="me-1">{r.icon}</p>
                    {t("title", { ns: r.name })}
                  </Link>
                </li>
              );
            })}
          <li className="px-2 transition-all hover:bg-secondry-color-500  rounded-lg  mb-1 py-1  ">
            <select
              ref={selectRef}
              className="px-2 transition-all bg-primary-color-500  outline-none focus:border-0 focus:ring-0  w-full bg-transparent  rounded-lg mb-1 py-1 "
              onChange={() => {
                languageHandel();
                menuHandler();
              }}
            >
              <option
                value="ar"
                className="px-2 transition-all bg-primary-color-500  flex items-center gap-2   rounded-lg  mb-1 py-1 "
              >
                العربية
              </option>
              <option
                value="en"
                className="px-2 transition-all  bg-primary-color-500   rounded-lg  mb-1 py-1 "
              >
                English
              </option>
            </select>
            {/* <p className="flex cursor-pointer items-center">
              <span className="me-1">
                <MdOutlineLanguage size={15} />
              </span>
              {t("lang", { ns: "navbar" })}
            </p> */}
          </li>
        </ul>
        <div>
          <div
            onClick={() => {
              logout();
            }}
            className="px-2 transition-all flex justify-center    items-center cursor-pointer gap-2  hover:bg-secondry-color-500  rounded-lg hover:text-primary-color-500 mb-1  "
          >
            <p className="me-1 ">{icon.logout}</p>
            <p className="me-1">{t("title", { ns: "logout" })}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
