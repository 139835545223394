import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import categoryServices from "../../services/categories";

// slicer for store dashboard config
export const getCategories = createAsyncThunk(
  "categories/getCategories",
  () => {
    return categoryServices.getCategories().then(async (res) => {
      if (res.data.last_page > 1) {
        const pagesArray = Array(res.data.lastKey).keys();
        const all = await Promise.all(
          pagesArray.map((p) => {
            return categoryServices.getCategories(p + 1);
          })
        );
        const mergeData = all.map((c) => c.data.data.data).flat(1);
        return mergeData;
      } else {
        return res.data.data.data;
      }
    });
  }
);

const initialState = {
  lang: null,
  auth: null,
  categories: {
    loading: true,
    data: [],
  },
};
const configSlicer = createSlice({
  name: "config",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      document.documentElement.lang = action.payload;
      document.body.dir = action.payload === "ar" ? "rtl" : "ltr";
      document.documentElement.dir = action.payload === "ar" ? "rtl" : "ltr";
      state.lang = action.payload;
    },
    setAuth: (state, action) => {
      state.auth = action.payload;
      window.localStorage.setItem("dc-auth", JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state) => {
      state.categories.loading = true;
      state.categories.data = [];
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.categories.loading = false;
      state.categories.data = action.payload;
    });
    builder.addCase(getCategories.rejected, (state) => {
      state.categories.loading = false;
      state.categories.data = [];
    });
  },
});

export const { setLanguage, setAuth } = configSlicer.actions;

export default configSlicer.reducer;
