import React from "react";
import { useTranslation } from "react-i18next";

const DSpinner = ({ position = "absolute" }) => {
    const { i18n } = useTranslation();
    return (
        <div
            className={`${position}  top-2/4 start-2/4 ${
                i18n.language === "ar" ? "translate-x-2/4" : "-translate-x-2/4"
            } -translate-y-2/4 `}
        >
            <div className="w-[100px] animate-spin h-[100px] border-4 rounded-full border-light-color  border-t-primary-color-500 border-r-primary-color-500 "></div>
        </div>
    );
};

export default DSpinner;
