import axios from "axios";
const { authHeader } = require("./headerConfig");

const API_ENDPOINT = process.env.REACT_APP_ENDPOINT + "/api/about-us";

const getAbout = async () => {
    const response = await axios.get(API_ENDPOINT, {
        withCredentials: true,
        headers: {
            ...authHeader(),
            Accept: "application/json",
        },
    });
    return response.data;
};
export default getAbout;
