import React, { memo } from "react";

const SkeletonImg = ({ skeletonStyle }) => {
    return (
        <div
            className={`h-full ${skeletonStyle} rounded-md  bg-light-color animate-pulse `}
        />
    );
};

export default memo(SkeletonImg);
