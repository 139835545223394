import { ErrorMessage, Field } from "formik";
import React, { memo } from "react";
import ErrorMsg from "./ErrorMsg";
const SelectField = ({
    type,
    name,
    id,
    label,
    className,
    children,
    options = [],
    ...rest
}) => {
    return (
        <div className="form-group mb-4 ">
            {label && (
                <label
                    htmlFor={id}
                    className="block  text-sm md:text-[1rem] mb-2"
                >
                    {label}:
                </label>
            )}
            <Field
                {...rest}
                as={"select"}
                name={name}
                id={id}
                className={`p-1 relative w-full  border rounded-lg transition-all  focus:border-third-color shadow-sm outline-none border-light-color ${className}`}
            >
                {options.map(item => (
                    <option key={item.id} value={item.value}>
                        {item?.title || item.value}
                    </option>
                ))}
            </Field>
            <ErrorMessage name={name} component={ErrorMsg} />
        </div>
    );
};

export default memo(SelectField);
