import React from "react";
// import utils
import { Navigate, Outlet, useLocation } from "react-router-dom";
// import components
import Header from "./Header";
import Footer from "./Footer";
import { getCookie } from "../utils/functions";

const ProtectedRoutes = () => {
    const { pathname } = useLocation();
    const token = getCookie("diamond-card-t");
    if (!token && pathname === "/login") {
        return <Outlet />;
    }
    if (!token) {
        return <Navigate to={"/login"} />;
    }
    if (token && pathname === "/login") {
        return <Navigate to={"/"} />;
    }
    return (
        <>
            <Header />
            <div className="relative  pt-[4rem] pb-[10rem] md:pt-[9rem] ">
                <div className="mx-2 md:container md:mx-auto p-4 relative ">
                    <Outlet />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ProtectedRoutes;
