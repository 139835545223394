import React, { useCallback, useState } from "react";
// import components
import DCard from "../components/DCard";
import { Heading, Spinner } from "@chakra-ui/react";
import FormikContainer from "../components/Formik/FormikContainer";
import FieldContainer from "../components/Formik/FieldContainer";
import DButton from "../components/DButton";
import { Form } from "formik";
import DImage from "../components/DImage";
// import utils
import * as Yup from "yup";
import { icon } from "../utils/icons";
import Logo from "../assets/Logo.png";
import { useTranslation } from "react-i18next";
import authServices from "../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { setCookie } from "../utils/functions";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { getCategories, setAuth } from "../redux/slicers/config";
import { useQuery } from "react-query";
import getAbout from "../services/about";
const initialValues = {
  username: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.config);
  const [t] = useTranslation("login");
  const { data, isLoading, error } = useQuery("getSocial", () => getAbout());
  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t("required", { ns: "validation-msg" })),
    password: Yup.string().required(t("required", { ns: "validation-msg" })),
  });
  const [showPassword, setShowPassword] = useState(false); // show / hide password state
  document.title = t("title");

  const socialIcons = {
    facebook: icon.facebook,
    whatsapp: icon.whatsapp,
    email: icon.email,
  };

  const socialMedia = (data) => {
    if (data?.data?.social_media) {
      const socialList = JSON.parse(data.data.social_media);
      let elements = [];
      for (let key in socialList) {
        elements.push(
          <li
            key={key}
            className="mx-1 border-2  text-primary-color-500 rounded-md"
          >
            <Link
              className="transition-all w-[30px]  h-[30px] hover:rotate-[360deg]  flex items-center justify-center"
              to={
                key === "email" ? "mailto:" + socialList[key] : socialList[key]
              }
              target="_blank"
            >
              {socialIcons[key]}
            </Link>
          </li>
        );
      }
      return elements.map((e) => e);
    }
  };
  // show / hide password handler
  const passwordToggleHandler = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);
  const onSubmit = (values, { setSubmitting }) => {
    authServices
      .login(values)
      .then((res) => {
        if (res.data.data.role === "customer") {
          localStorage.setItem('telegram_authorize', JSON.stringify( res.data.data.telegram_authorize));
          localStorage.setItem('role', JSON.stringify( res.data.data.role));
          setCookie(
            "diamond-card-t",
            res.data.data.api_key,
            res.data.data.expires,
          );
          if (res.data.data.telegram_authorize) {
            toast.success(
              t("login-telegram-authorize", { ns: "validation-msg" })
            );
            setTimeout(() => {
              navigate("/wating-page", { replace: true });
            }, 1500);
          } else {
            authServices
              .getUserInfo()
              .then((userRes) => {
                dispatch(
                  setAuth({
                    username: values.username,
                    telegramAuthorize: res.data.data.telegram_authorize,
                    role: res.data.data.role,
                    level: res.data.data.level,
                    ...userRes.data.data,
                  })
                );
                dispatch(getCategories());
                toast.success(t("login-success", { ns: "validation-msg" }));
                setTimeout(() => {
                  navigate("/", { replace: true });
                }, 1500);
              })
              .catch((err) => {
                if (err?.response?.status === 403) {
                  setCookie("diamond-card-t", "", -1);
                  dispatch(setAuth(null));
                  console.log(err);
                  toast.error(t("suspened", { ns: "validation-msg" }));
                } else {
                  console.log(err);
                }
                setSubmitting(false);
              });
          }
        } else {
          toast.error(t("login-failed", { ns: "validation-msg" }));
          setSubmitting(false);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          toast.error(t("login-failed", { ns: "validation-msg" }));
        } else {
          toast.error(t("server-error", { ns: "validation-msg" }));
        }
        setSubmitting(false);
      });
  };
  return (


    <DCard
      className={`fixed w-[90%] mx-auto sm:w-2/4 xl:w-1/4  top-2/4 translate-x-2/4 ${
        lang === "ar" ? "start-2/4" : "end-2/4"
      } -translate-y-2/4 bg-white`}
    >
      <div className="text-center mb-4 text-third-color">
        <DImage src={Logo} alt={"Logo"} className="w-[100px] mx-auto" />
        <Heading as={"h1"}>{t("title")}</Heading>
      </div>
      <FormikContainer
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => {
          return (
            <Form className="text-third-color">
              <FieldContainer
                type="text"
                name="username"
                label={t("username")}
                id="username"
              />
              <FieldContainer
                type={showPassword ? "text" : "password"}
                name="password"
                label={t("password")}
                id="password"
              >
                <DButton
                  type="button"
                  onClick={passwordToggleHandler}
                  varient="transparent"
                  className="absolute end-0 h-full text-xl text-light-color"
                >
                  {showPassword ? icon.eyeHide : icon.eyeShow}
                </DButton>
              </FieldContainer>
              <DButton
                disabled={isSubmitting}
                type="submit"
                className="w-full flex items-center justify-center text-white"
              >
                {isSubmitting ? <Spinner /> : t("submit")}
              </DButton>
            </Form>
          );
        }}
      </FormikContainer>
      {!error && (
        <div className="mt-4 text-center">
          <Heading as="h4" size="sm" className="mb-4">
            -------- تواصل معنا --------
          </Heading>
          <ul className="flex justify-center">{socialMedia(data)}</ul>
        </div>
      )}
    </DCard>
  );
};

export default Login;
