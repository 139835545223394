import React from "react";
import Image from "../assets/404.svg";
import DImage from "./DImage";
const NotFound = ({ desc }) => {
    return (
        <div className="text-center">
            <DImage
                src={Image}
                alt="404"
                className={"mx-auto w-3/4 md:w-2/4 xl:w-1/4"}
            />
            {desc && <p className="text-xl">{desc}</p>}
        </div>
    );
};

export default NotFound;
