import React from "react";
import TextField from "./Fields/TextField";
import FileField from "./Fields/FileField";
import SelectField from "./Fields/SelectField";

const FieldContainer = ({
    type,
    name,
    id,
    options,
    label,
    fileRef,
    ...rest
}) => {
    switch (type) {
        case "text":
        case "email":
        case "password":
            return (
                <TextField
                    type={type}
                    name={name}
                    id={id}
                    label={label}
                    {...rest}
                />
            );
        case "file":
            return (
                <FileField
                    ref={fileRef}
                    type={type}
                    name={name}
                    id={id}
                    {...rest}
                />
            );
        case "select":
            return (
                <SelectField
                    type={type}
                    name={name}
                    id={id}
                    label={label}
                    options={options}
                    {...rest}
                />
            );
        default:
            return (
                <TextField
                    type={"text"}
                    name={name}
                    id={id}
                    label={label}
                    {...rest}
                />
            );
    }
};

export default FieldContainer;
