import { lazy } from "react";
import { icon } from "./icons";

// import components
// optimzation using lazy loading
const Logout = lazy(() => import("../pages/Logout"));
const Index = lazy(() => import("../pages/Index"));
const TelegramAccount = lazy(() => import("../pages/TelegramAccount"));
const Orders = lazy(() => import("../pages/Orders"));
const Withdraw = lazy(() => import("../pages/Withdraw"));
const WithdrawDetails = lazy(() => import("../pages/WithdrawDetails"));
const RequestedBalance = lazy(() => import("../pages/RequestedBalance"));
const AddRequestBalance = lazy(() => import("../pages/AddRequestBalance"));
const AboutUs = lazy(() => import("../pages/About"));
const Products = lazy(() => import("../pages/Products"));
const Categories = lazy(() => import("../pages/Categories"));
const SubProducts = lazy(() => import("../pages/SubProducts"));
const ProductDetails = lazy(() => import("../pages/ProductDetails"));
const BalanceHistory = lazy(() => import("../pages/BalanceHistory"));
const Profile = lazy(() => import("../pages/Profile"));
export const routes = [
  {
    id: 1,
    name: "home",
    icon: icon.home,
    index: true,
    path: "/",
    type: ["route", "collapse"],
    element: <Index />,
  },
  {
    id: 2,
    name: "products",
    icon: icon.products,
    path: "/:id/products",
    type: ["route", "collapse"],
    element: <Products />,
  },

  {
    id: 3,
    name: "categories",
    icon: icon.products,
    path: "/categories",
    type: ["route", "collapse"],
    element: <Categories />,
  },
  {
    id: 4,
    name: "orders",
    icon: icon.orders,
    path: "/orders",
    type: ["route", "collapse"],
    element: <Orders />,
  },

  {
    id: 5,
    name: "withdraw",
    icon: icon.dollar,
    path: "/withdraw",
    type: ["route", "collapse"],
    element: <Withdraw />,
  },
  {
    id: 6,
    name: "profile",
    icon: icon.user,
    path: "/profile",
    type: ["route", "collapse"],
    element: <Profile />,
  },
  {
    id: 7,
    name: "balance-history",
    icon: icon.dollar,
    path: "/balance-history",
    type: ["route", "collapse"],
    element: <BalanceHistory />,
  },
  // {
  //   id: 8,
  //   name: "requested-balance",
  //   icon: icon.dollar,
  //   path: "/balance-requested",
  //   type: ["route", "collapse"],
  //   element: <RequestedBalance />,
  // },
  {
    id: 9,
    name: "telegram-account",
    icon: icon.telegram,
    path: "/telegram-account",
    type: ["route", "collapse"],
    element: <TelegramAccount />,
  },
  {
    id: 11,
    name: "about-us",
    icon: icon.info,
    path: "/about",
    type: ["route", "collapse"],
    element: <AboutUs />,
  },
  // {
  //   id: 10,
  //   name: "logout",
  //   icon: icon.logout,
  //   path: "/logout",
  //   type: ["route", "collapse"],
  //   element: <Logout />,
  // },

  {
    id: 12,
    name: "profile",
    icon: icon.user,
    path: "/profile",
    type: ["route"],
    element: <Profile />,
  },
  {
    id: 13,
    name: "subproducts",
    path: "/:categoryId/products/:id/sub-products",
    type: ["route"],
    element: <SubProducts />,
  },
  {
    id: 14,
    name: "subproducts",
    path: "/:categoryId/products/:id/sub-products/:subId",
    type: ["route"],
    element: <ProductDetails />,
  },
  {
    id: 15,
    name: "balance-history",
    path: "/balance-history",
    type: ["route"],
    element: <BalanceHistory />,
  },
  {
    id: 16,
    name: "telegram-account",
    path: "/telegram-account",
    type: ["route"],
    element: <TelegramAccount title="telegram-account" />,
  },
  {
    id: 17,
    name: "withdraw-details",
    icon: icon.dollar,
    path: "/withdraw/:id",
    type: ["route"],
    element: <WithdrawDetails />,
  },
  {
    id: 18,
    name: "requested-balance-add",
    path: "/balance-requested/add",
    type: ["route"],
    element: <AddRequestBalance />,
  },
];

// const menuItems = [
//     {
//       id: 1,
//       name: (
//         <p className="flex items-center">
//           <span className="me-1">{icon.user}</span>
//           {t("profile")}
//         </p>
//       ),
//       clickHandler: () => navigate("/profile"),
//     },
//     {
//       id: 2,
//       name: (
//         <p className="flex items-center">
//           <span className="me-1">{icon.lang}</span>
//           {t("lang")}
//         </p>
//       ),
//       clickHandler: () => {
//         dispatch(setLanguage(lang === "en" ? "ar" : "en"));
//         i18n.changeLanguage(lang === "en" ? "ar" : "en");
//       },
//     },
//     {
//       id: 3,
//       name: (
//         <p className="flex items-center">
//           <span className="me-1">{icon.dollar}</span>
//           {t("title", { ns: "balance-history" })}
//         </p>
//       ),
//       clickHandler: () => navigate("/balance-history"),
//     },
//     {
//       id: 4,
//       name: (
//         <p className="flex items-center">
//           <span className="me-1">{icon.logout}</span>
//           {t("logout")}
//         </p>
//       ),
//       clickHandler: () => {
//         authServices.logout();
//         setCookie("diamond-card-t", "", -1);
//         dispatch(setAuth(null));
//         navigate("/login", { replace: true });
//       },
//     },
//   ];
