import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import React, { memo } from "react";
import ErrorMsg from "./ErrorMsg";
const TextField = ({
    type,
    name,
    id,
    label,
    className,
    children,
    relatedWith,
    relatedWithHandler,
    ...rest
}) => {
    const { values, setValues } = useFormikContext();
    const [field] = useField(name);
    const changeHandler = e => {
        if (relatedWith && relatedWithHandler) {
            setValues({
                ...values,
                ...relatedWithHandler(e.target.value),
            });
        }
        field.onChange(e);
    };
    return (
        <div className="form-group mb-4 relative">
            {label && (
                <label
                    htmlFor={id}
                    className="block  text-sm md:text-[1rem] mb-2"
                >
                    {label}:
                </label>
            )}
            <div className="relative">
                <Field
                    className={`p-2 relative w-full  border rounded-lg transition-all  focus:border-third-color shadow-sm outline-none border-light-color ${className}`}
                    name={name}
                    type={type}
                    id={id}
                    {...rest}
                    onChange={changeHandler}
                />
                {children}
            </div>
            <ErrorMessage name={name} component={ErrorMsg} />
        </div>
    );
};

export default memo(TextField);
