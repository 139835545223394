import React, { memo, useState } from "react";
import SkeletonImg from "./Skeletons/SkeletonImg";
const DImage = ({
    src,
    alt,
    load = false,
    className,
    skeletonStyle,
    ...rest
}) => {
    const [loading, setLoading] = useState(true);
    return (
        <>
            {load && loading && <SkeletonImg skeletonStyle={skeletonStyle} />}
            <img
                {...rest}
                src={src}
                onLoad={() => setLoading(false)}
                alt={alt}
                className={`relative aspect-auto${
                    loading ? "-z-[10]" : ""
                } ${className}`}
            />
        </>
    );
};

export default memo(DImage);
