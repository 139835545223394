import React, { forwardRef, memo } from "react";

// import images
import Logo from "../assets/Logo.png";
import Avatar from "../assets/avatar.png";

// import utils
import { Link } from "react-router-dom";
import { icon } from "../utils/icons";

// import component

import { Heading, Skeleton } from "@chakra-ui/react";
import DImage from "./DImage";
import DButton from "./DButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formattedLevel } from "../utils/functions";

const Navbar = forwardRef(({ menuHandler }, ref) => {
  const [t] = useTranslation("navbar");
  const { auth } = useSelector((state) => state.config);

  return (
    <div
      ref={ref}
      className="text-primary-color-500 navbar h-[75px] md:h-auto  flex items-center justify-between p-1 px-4  bg-white"
    >
      <div className="flex items-center">
        <DButton
          onClick={menuHandler}
          varient="transparent"
          className="block text-2xl md:hidden"
        >
          {icon.menu}
        </DButton>
        <div className="flex items-center avatar">
          <div className="relative ">
            <DImage
              src={Avatar}
              alt="avatar"
              className="w-[30px] md:w-[40px] me-2  rounded-full bg-body-color"
            />
            <div className="absolute -bottom-1 end-1 md:end-0 md:w-[20px] md:h-[20px]  text-xs text-center font-bold bg-secondry-color-500 w-[15px] h-[15px] rounded-full">
              {formattedLevel(auth?.level)}
            </div>
          </div>

          <Heading className="hidden lg:block" as="h2" size={["xs", "sm"]}>
            {auth ? auth.username : <Skeleton className="w-[100px] h-[15px]" />}
          </Heading>
        </div>
        {auth ? (
          <div className="p-1 rounded-lg cursor-pointer ms-2 bg-secondry-color-500">
            <div className="inline-block max-sm:text-xs me-1">
              {t("balance")}: {auth.balance.toFixed(2)}
            </div>
          </div>
        ) : (
          <Skeleton className="h-[15px] w-[100px]" />
        )}
      </div>
      <Link to={"/"}>
        <DImage src={Logo} alt="logo" className="w-[90px]" />
      </Link>
    </div>
  );
});

export default memo(Navbar);
