import React from "react";

const DCard = ({ bgColor = "white", children, className }) => {
    const bgColorType = {
        primary: "bg-primary-color-500",
        secondry: "bg-secondry-color-500",
        third:"bg-third-color",
        white: "bg-white",
    };
    return (
        <div
            className={`${bgColorType[bgColor]} shadow-sm rounded-lg p-4 ${className}`}
        >
            {children}
        </div>
    );
};

export default DCard;
