import React, { useCallback, useEffect, useRef, useState } from "react";
import { routes } from "../utils/routes";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
// import components
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import DCard from "./DCard";
import Overlay from "./Overlay";
import authServices from "../services/auth";
import { setCookie } from "../utils/functions";
import { setAuth, setLanguage } from "../redux/slicers/config";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const navRef = useRef(); // ref for navbar
  const activePath = useLocation();
  const [navHeight, setNavHeight] = useState(116); // default height of navbar
  const [toggleMenu, setToggleMenu] = useState(false); // open/hide sidebar in mobile screen
  const [t, i18n] = useTranslation(["home", "orders"]);
  const { lang } = useSelector((state) => state.config);

  // shiw / hide sidebar handler
  const toggleMenuHandler = useCallback(() => {
    setToggleMenu(!toggleMenu);
  }, [toggleMenu]);

  const resizeHandler = () => {
    if (window.innerWidth > 767) {
      setToggleMenu(false);
    }
  };
  const navigate = useNavigate();
  const logout = () => {
    authServices.logout();
    setCookie("diamond-card-t", "", -1);
    dispatch(setAuth(null));
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    // set new height of navbar
    window.addEventListener("resize", resizeHandler);
    if (navRef.current) {
      setNavHeight(navRef.current.clientHeight);
    }

    return () => window.addEventListener("resize", resizeHandler);
  }, []);
  return (
    <>
      <Overlay setToggleMenu={toggleMenuHandler} show={toggleMenu} />
      <div className="fixed z-50 w-full bg-white ">
        <DCard className="p-[0_!important] relative z-[50] md:container md:mx-auto ">
          <Navbar menuHandler={toggleMenuHandler} ref={navRef} />
          <Sidebar
            activePath={activePath.pathname}
            showSidebar={toggleMenu}
            menuHandler={toggleMenuHandler}
            navHeight={navHeight}
          />
          <div
            className={`bg-white py-2 px-4 max-lg:overflow-x-scroll border-t border-light-color md:flex justify-center hidden`}
          >
            <ul className="flex items-center">
              {routes
                .filter((r) => r.type.includes("collapse"))
                .map((r) => {
                  return (
                    <li key={r.id}>
                      <Link
                        to={r.name === "products" ? "/1/products" : r.path}
                        className={`block text-center rounded-full py-1 px-3  ${
                          activePath.pathname === r.path ||
                          activePath.pathname.includes(r.name)
                            ? "bg-primary-color-500 text-white"
                            : ""
                        }`}
                      >
                        {t("title", { ns: r.name })}
                      </Link>
                    </li>
                  );
                })}
              <li
                onClick={() => {
                  dispatch(setLanguage(lang === "en" ? "ar" : "en"));
                  i18n.changeLanguage(lang === "en" ? "ar" : "en");
                }}
                className="px-3 py-1 "
              >
                <p className="flex items-center cursor-pointer">
                  <span className="me-1"></span>
                  {t("lang", { ns: "navbar" })}
                </p>
              </li>

              <li
                onClick={() => {
                  logout();
                }}
                className="px-3 py-1"
              >
                <p className="flex items-center cursor-pointer">
                  <span className="me-1"></span>
                  {t("title", { ns: "logout" })}
                </p>
              </li>
            </ul>
          </div>
        </DCard>
      </div>
    </>
  );
};

export default Header;
