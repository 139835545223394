import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import getAbout from "../services/about";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import authServices from "../services/auth";
import { getCategories, setAuth } from "../redux/slicers/config";
import { useTranslation } from "react-i18next";
import { setCookie } from "../utils/functions";
const WattingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [redirectTimer, setRedirectTimer] = useState(60);
  const [t] = useTranslation("waitting-page");
  document.title = t("title");
  useEffect(() => {
    const interval = setInterval(() => {
      // Place your code here to check the request
      getAbout()
        .then((res) => {
          if (res) {
            authServices
              .getUserInfo()
              .then((userRes) => {
                dispatch(
                  setAuth({
                    username: userRes.username,
                    telegramAuthorize: JSON.parse(
                      localStorage.getItem("telegram_authorize")
                    ),
                    role: JSON.parse(localStorage.getItem("role")),
                    ...userRes.data.data,
                  })
                );
                localStorage.removeItem("telegram_authorize");
                localStorage.removeItem("role");
                dispatch(getCategories());
                toast.success(t("login-success", { ns: "validation-msg" }));
                setTimeout(() => {
                  navigate("/", { replace: true });
                }, 1500);
              })
              .catch((err) => {
                if (err?.response?.status === 404) {
                  setCookie("diamond-card-t", "", -1);
                  dispatch(setAuth(null));
                  console.log(err);
                  toast.error(t("suspened", { ns: "validation-msg" }));
                  navigate("/", { replace: true });
                }
              });
          }
        })
        .catch((err) => {
          console.log("Checking err...");
        });
      setRedirectTimer((prevTimer) => prevTimer - 5);
    }, 5000); // Interval set to 5000 milliseconds (5 seconds)

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch, navigate,t]); 
  useEffect(() => {
    if (redirectTimer === 0) {
      localStorage.removeItem("telegram_authorize");
      localStorage.removeItem("role");
      setCookie("diamond-card-t", "", -1);
      dispatch(setAuth(null));
      navigate("/login"); // Redirect to login page
    }
  }, [navigate, redirectTimer,dispatch]);

  return (
    <div className="fixed h-screen top-0 left-0 w-full flex justify-center items-center z-10">
      <div className="p-4 rounded-md">
        <div className="flex  items-center flex-col  justify-center">
          <div className=" text-2xl font-bold">
            {t("wait-message")}
          </div>
          <div className="flex justify-center">
            <motion.span
              className="w-4 h-4 my-12 mx-1 bg-black rounded-full"
              animate={{
                y: [0, -20, 0],
                opacity: [1, 0], // Fades out
                transition: { duration: 1, repeat: Infinity },
              }}
            />
            <motion.span
              className="w-4 h-4 my-12 mx-1 bg-black rounded-full"
              animate={{
                y: [0, -20, 0],
                opacity: [1, 0], // Fades out
                transition: { duration: 1, repeat: Infinity, delay: 0.2 },
              }}
            />
            <motion.span
              className="w-4 h-4 my-12 mx-1 bg-black rounded-full"
              animate={{
                y: [0, -20, 0],
                opacity: [1, 0], // Fades out
                transition: { duration: 1, repeat: Infinity, delay: 0.4 },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default WattingPage;
