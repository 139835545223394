import axios from "axios";
const { authHeader } = require("./headerConfig");

const API_ENDPOINT = process.env.REACT_APP_ENDPOINT + "/api";

const getCategories = (page = 1) => {
    return axios.get(`${API_ENDPOINT}/categories?page=${page}`, {
        withCredentials: true,
        headers: {
            ...authHeader(),
            Accept: "application/json",
        },
    });
};

const categoryServices = { getCategories };
export default categoryServices;
