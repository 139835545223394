import React, { Suspense, lazy, useEffect, useRef } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { routes } from "./utils/routes";
import ProtectedRoutes from "./components/ProtectedRoutes";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import authServices from "./services/auth";
import { getCategories, setAuth, setLanguage } from "./redux/slicers/config";
import { getCookie, setCookie } from "./utils/functions";
import Login from "./pages/Login";
import DSpinner from "./components/DSpinner";
import E404 from "./pages/E404";
import WattingPage from "./pages/WattingPage";
const queryClient = new QueryClient();

const theme = extendTheme({
  fonts: {
    body: "Cairo-Medium ,  Arial, Helvetica, sans-serif",
    heading: "Cairo-Medium ,  Arial, Helvetica, sans-serif",
  },
  colors: {
    primary: {
      100: "#f7fafc",
    },
  },
});
const App = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const interval = useRef();
  useEffect(() => {
    const checkAuth = () => {
      authServices
        .getUserInfo()
        .then((res) => {
          dispatch(
            setAuth({
              ...JSON.parse(window.localStorage.getItem("dc-auth")),
              ...res.data.data,
            })
          );
        })
        .catch((err) => {
          setCookie("diamond-card-t", "", -1);
          dispatch(setAuth(null));
          navigate("/login", { replace: true });
        });
    };
    dispatch(setLanguage(i18n.language));

    if (
      JSON.parse(window.localStorage.getItem("dc-auth")) &&
      getCookie("diamond-card-t")
    ) {
      dispatch(setAuth(JSON.parse(window.localStorage.getItem("dc-auth"))));
      dispatch(getCategories());
      checkAuth();
      interval.current = setInterval(checkAuth, 30000);
    }
    return () => {
      clearInterval(interval.current);
    };
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <div className="App text-third-color relative">
          <Toaster />
          <Routes>
            <Route
              element={
                <Suspense fallback={<DSpinner position="fixed" />}>
                  <ProtectedRoutes />
                </Suspense>
              }
            >
              {routes.map((r) => (
                <Route key={r.id} {...r} />
              ))}
              <Route path="/login" element={<Login />} />
            </Route>
            <Route path="*" element={<E404 />} />{" "}
            <Route path="/wating-page" element={<WattingPage />} />
          </Routes>
        </div>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default App;
