import React from "react";

const Overlay = ({ show ,setToggleMenu }) => {
    return (
        <div onClick={setToggleMenu}
            className={`transition-all fixed top-0 start-0 w-full h-full bg-third-color/50 z-[10] ${
                show ? "visible opacity-1" : "invisible opacity-0"
            }`}
        ></div>
    );
};

export default Overlay;
