import axios from "axios";
import { authHeader } from "./headerConfig";

const API_ENDPOINT = process.env.REACT_APP_ENDPOINT + "/api";

const login = (data) => {
  return axios.post(API_ENDPOINT + "/auth/login", data, {
    headers: {
      Accept: "application/json",
    },
  });
};

const getUserInfo = () => {
  return axios.get(API_ENDPOINT + "/me", {
    withCredentials: true,
    headers: {
      ...authHeader(),
      Accept: "application/json",
    },
  });
};

const updatePassword = (data) => {
  return axios.put(API_ENDPOINT + "/new-password", data, {
    withCredentials: true,
    headers: {
      ...authHeader(),
      Accept: "application/json",
    },
  });
};

const logout = () => {
  return axios.post(
    API_ENDPOINT + "/auth/logout",
    {},
    {
      withCredentials: true,
      headers: {
        ...authHeader(),
        Accept: "application/json",
      },
    }
  );
};
const authServices = {
  login,
  logout,
  updatePassword,
  getUserInfo,
};
export default authServices;
