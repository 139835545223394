import React from "react";
// import components
import { useTranslation } from "react-i18next";
import NotFound from "../components/NotFound";

const E404 = () => {
    const [t, i18n] = useTranslation("e404");
    return (
        <div
            className={`fixed w-full text-center top-2/4 start-2/4 ${
                i18n.language === "ar" ? "translate-x-2/4" : "-translate-x-2/4"
            } -translate-y-2/4`}
        >
            <NotFound desc={t("title")} />
        </div>
    );
};

export default E404;
